<template lang="">
    <div>
        <Detay />
    </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
    name:"kiracidetay",
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: 'Kiracı Detay', route: '/kiracilar/detay/1/' },
        ]);
    },
    components:{
        Detay: () => import("@/components/kiracilar/Detay")
    }
}
</script>
<style lang="">
    
</style>